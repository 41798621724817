// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-js": () => import("/Users/john/Lab/clients/test/src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-blog-js": () => import("/Users/john/Lab/clients/test/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-pages-404-js": () => import("/Users/john/Lab/clients/test/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/john/Lab/clients/test/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("/Users/john/Lab/clients/test/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("/Users/john/Lab/clients/test/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/john/Lab/clients/test/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-index-js": () => import("/Users/john/Lab/clients/test/src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/john/Lab/clients/test/.cache/data.json")

